import { useCallback } from "react";

import { Consequence } from "../types";
import { useMemberConsequences } from "./useMemberConsequences";

export const useMemberConsequence = (
  consequenceId: Consequence["id"],
  userId: number
) =>
  useMemberConsequences(userId, {
    select: useCallback(
      (data: Consequence[]) =>
        data.find((consequence) => consequence.id === consequenceId),
      [consequenceId]
    ),
  });
