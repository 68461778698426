import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { MemberReferral } from "@core/services/nocd-api/members";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import invariant from "tiny-invariant";

import getQueryKeys from "../../query-keys";

interface CreateMemberOutboundReferralPayload {
  user_id: number;
  problem_area: string;
  type_of_treatment: string;
  needs_treatment_for_psychotic_disorder: number;
  is_actively_suicidal: number;
  admitted_to_hospital_for_psychiatric_reason: number;
  has_medicaid: number;
  request_notes: string;
  clinician_signed_email: string;
  status: string;
  setting_preference: string;
  preferred_times?: {
    id: string;
    day: string;
    timeOfDay: string;
  }[];
}

const createMemberOutboundReferral = (
  payload: CreateMemberOutboundReferralPayload,
  accessToken: string | undefined
) => {
  invariant(payload.user_id != null, "user_id is required");
  return api
    .post<MemberReferral[]>(
      "/v1/care_coordination/outbound_referrals",
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);
};

export const useCreateOutboundReferral = (): UseMutationResult<
  MemberReferral[],
  Error,
  CreateMemberOutboundReferralPayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => createMemberOutboundReferral(payload, accessToken),
    {
      onSuccess: (_data, { user_id }) =>
        queryClient.invalidateQueries(
          getQueryKeys(accessToken).memberOutboundReferrals(user_id)
        ),
    }
  );
};
