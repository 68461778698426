import { useSession } from "@core/hooks/useSession";
import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import api from "../../api";
import { transformAPIError } from "../../utils";
import getQueryKeys from "../query-keys";

interface SupportGroupCustomFields {
  cf_service_category?: string;
  cf_retool_id?: number;
  cf_therapist?: string;
}

export interface SupportTicket {
  associated_tickets_count?: number;
  association_type?: string;
  cc_emails: string[];
  company_id?: number;
  created_at: string;
  custom_fields: SupportGroupCustomFields;
  due_by: string;
  email_config_id?: number;
  form_id?: number;
  fr_due_by: string;
  fr_escalated: boolean;
  fwd_emails: string[];
  group_id: number;
  id: number;
  is_escalated: boolean;
  nr_due_by?: string;
  nr_escalated: boolean;
  priority: number;
  product_id?: number;
  reply_cc_emails: string[];
  requester_id: number;
  responder_id?: number;
  source: number;
  spam: boolean;
  status: number;
  subject: string;
  support_email?: string;
  tags: string[];
  ticket_cc_emails: string[];
  to_emails?: string[];
  type?: string;
  updated_at: string;
}

const getMemberSupportTickets = (
  userId: number,
  accessToken: string | undefined
): Promise<SupportTicket[]> =>
  api
    .get<SupportTicket[]>(
      `/v1/therapy/members/${userId}/support_tickets`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);

export const useMemberSupportTickets = (
  userId: number,
  options?: UseQueryOptions<SupportTicket[], Error, SupportTicket[]>
): UseQueryResult<SupportTicket[], Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).memberSupportTickets(userId) as QueryKey,
    () => getMemberSupportTickets(userId, accessToken),
    {
      enabled: !!accessToken && userId != null,
      ...(options ?? {}),
    }
  );
};
