import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { MemberContacts } from "@core/services/nocd-api/members";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import invariant from "tiny-invariant";

import getQueryKeys from "../query-keys";

interface SaveMemberContactPayload {
  user_id: number;
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  contact_type: string | null;
}

const saveMemberContacts = (
  payload: SaveMemberContactPayload,
  accessToken: string | undefined
) => {
  invariant(payload.user_id != null, "user_id is required");
  return api
    .post<MemberContacts[]>(
      `/v2/clinician/members/${encodeURIComponent(
        payload.user_id
      )}/member_contacts`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);
};

export const useSaveMemberContacts = (): UseMutationResult<
  MemberContacts[],
  Error,
  SaveMemberContactPayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  const queryClient = useQueryClient();

  return useMutation((payload) => saveMemberContacts(payload, accessToken), {
    onSuccess: (_data, payload) =>
      queryClient.invalidateQueries(
        getQueryKeys(accessToken).memberContacts(payload.user_id)
      ),
  });
};
