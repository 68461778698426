import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { MemberHigherLevelOfCareNote } from "@core/services/nocd-api/members";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import invariant from "tiny-invariant";

import getQueryKeys from "../../query-keys";

export type DeleteHLOCNotePayload = Pick<
  MemberHigherLevelOfCareNote,
  "id" | "user_id"
>;

const deleteHLOCNote = (
  payload: DeleteHLOCNotePayload,
  accessToken: string | undefined
) => {
  invariant(payload.user_id != null, "user_id is required");
  return api
    .post<MemberHigherLevelOfCareNote[]>(
      `/v2/clinician/members/${encodeURIComponent(payload.user_id)}/hloc_notes`,
      {
        ...payload,
        action: "DELETE",
      },
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);
};

export const useDeleteHLOCNote = (): UseMutationResult<
  MemberHigherLevelOfCareNote[],
  Error,
  DeleteHLOCNotePayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation((payload) => deleteHLOCNote(payload, accessToken), {
    onSuccess: (_data, payload) =>
      queryClient.invalidateQueries(
        getQueryKeys(accessToken).memberHLOCNotes(payload.user_id)
      ),
  });
};
