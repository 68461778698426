import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import getQueryKeys from "../query-keys";

interface ReprocessAssessementsPayload {
  user_id: number;
}

const reprocessAssessements = (
  payload: ReprocessAssessementsPayload,
  accessToken: string | undefined
) => {
  return api
    .post(
      `/v2/clinician/members/${encodeURIComponent(
        payload.user_id
      )}/reprocess_assessments_scores`,
      payload,
      {
        headers: {
          Authorization: accessToken,
        },
      }
    )
    .catch(transformAPIError);
};

export const useReprocessAssessmentResults = (): UseMutationResult<
  unknown,
  Error,
  ReprocessAssessementsPayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation((payload) => reprocessAssessements(payload, accessToken), {
    onSuccess: (_data, payload) =>
      queryClient.invalidateQueries(
        getQueryKeys(accessToken).memberAssessmentResultsScores(payload.user_id)
      ),
  });
};
