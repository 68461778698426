import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import _ from "lodash";
import { useQuery, UseQueryResult } from "react-query";

import { isNotNilOrEmpty } from "../constants";
import { AccountType } from "./stores/useAccountType";

interface IntakeCallSummary {
  assigned_to: string;
  call_at: string;
  id: number;
}

export interface PremiumUserResponse {
  account_type: AccountType;
  address: string;
  apero_patient_id: string;
  app_user: boolean;
  appointments: [];
  assigned_clinician_user_id?: number;
  billing_additional_notes?: string;
  billing_cash_pay_30_min?: boolean;
  billing_cash_pay_45_min?: boolean;
  billing_cash_pay_60_min?: boolean;
  billing_cash_pay_90_min?: boolean;
  billing_cash_pay_payment_plan?: boolean;
  billing_cash_pay_payment_terms?: string;
  billing_ins_coverage?: string;
  billing_ins_sub_dob?: string;
  billing_ins_sub_first_name?: string;
  billing_ins_sub_last_name?: string;
  billing_notes?: string;
  billing_plan?: string;
  cancellation_reason?: string;
  city: string;
  country_code: string;
  customer_id: string;
  clinician_email?: string;
  default_currency: string;
  diagnostic_date?: string;
  diagnostic_status?: string;
  diagnostic_timezone: string;
  do_not_book: boolean;
  dob?: string;
  email: string;
  first_name: string;
  has_kp_referral: boolean;
  how_did_you_hear_about_nocd?: string;
  insurance?: string;
  insurance_provider: string;
  is_linked_account: boolean;
  is_root_account: boolean;
  insurance_card_upload_status?: string;
  intake_calls: IntakeCallSummary[];
  is_complete: boolean;
  last_name: string;
  link_id?: number;
  linked_accounts: number[];
  notes?: string;
  peer_advisor_email?: string;
  phone: string;
  preferred_name?: string;
  protocol_type?: string;
  rendered_diagnostic_date?: string;
  root_account_id?: number;
  rp_email: string;
  rp_name: string;
  rp_phone: string;
  rp_relation: string;
  school?: string;
  specialties_json: string[] | null;
  state: string;
  therapist_preferences?: unknown;
  user_id: string;
  using_medicaid_or_medicare?: 0 | 1;
  zipcode: string;
  zocdoc_patient?: boolean;
  parents_living_status: string | null;
  gov_healthcare_program: string | null;
  has_shared_custody: string | null;
  second_parent_first_name: string | null;
  second_parent_last_name: string | null;
  second_parent_email: string | null;
  notify_about_earlier_sessions: boolean | null;
  required_therapist_gender: string | null;
  required_therapist: string | null;
  app_installed: string | null;
}

export const getPremiumUserQueryKey = (user_id: number) => [
  "premium-user",
  user_id,
];

export const usePremiumUser = (
  user_id: number
): UseQueryResult<PremiumUserResponse, Error> => {
  const { data: session } = useSession();
  const accessToken = session?.accessToken;

  return useQuery(
    getPremiumUserQueryKey(user_id),
    async () => {
      return api
        .get<PremiumUserResponse>(
          `v1/retool/intake/get_premium_user?user_id=${user_id}`,
          {
            headers: {
              Authorization: accessToken,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          return data;
        })
        .catch(transformAPIError);
    },
    {
      cacheTime: 20 * 60 * 1000, // 20 minutes
      staleTime: 20 * 60 * 1000, // 20 minutes
    }
  );
};

export const basicInfoSavedAndCompleted = (pu: PremiumUserResponse) => {
  function calculateMissingInfo(
    firstName: readonly [boolean, "First Name"],
    lastName: readonly [boolean, "Last Name"],
    phone: readonly [boolean, "Phone"],
    dob: readonly [boolean, "Date of Birth"],
    address: readonly [boolean, "Address"],
    city: readonly [boolean, "City"],
    state: readonly [boolean, "State"],
    zipcode: readonly [boolean, "Zipcode"],
    timezone: readonly [boolean, "Timezone"],
    responsibleParty: readonly [boolean, "Responsible Party (minor)"],
    parentLivingStatus: readonly [boolean, "Parent Living Status"]
  ) {
    const missingPersonalInfo: string[] = [];
    [firstName, lastName, phone, dob].forEach(([isValid, label]) => {
      if (!isValid) {
        missingPersonalInfo.push(label);
      }
    });

    const missingAddressInfo: string[] = [];
    if (
      pu.country_code === "US" ||
      pu.country_code === "" ||
      pu.country_code === null ||
      pu.country_code === undefined
    ) {
      missingAddressInfo.push("US");
      [address, city, state, zipcode].forEach(([isValid, label]) => {
        if (!isValid) {
          missingAddressInfo.push(label);
        }
      });
    } else if (pu.country_code === "CA") {
      missingAddressInfo.push("Canada");
      [address, state].forEach(([isValid, label]) => {
        if (!isValid) {
          missingAddressInfo.push(label);
        }
      });
    } else {
      missingAddressInfo.push("Other Country");
      if (!address[0]) {
        missingAddressInfo.push("Address");
      }
    }

    const missingOtherInfo: string[] = [];
    [timezone, responsibleParty, parentLivingStatus].forEach(
      ([isValid, label]) => {
        if (!isValid) {
          missingOtherInfo.push(label);
        }
      }
    );

    const missingInfo = {
      basicInfo: missingPersonalInfo,
      addressInfo: missingAddressInfo,
      otherInfo: missingOtherInfo,
    };
    return missingInfo;
  }

  if (pu) {
    // Basic Info
    const firstName = [isNotNilOrEmpty(pu.first_name), "First Name"] as const;
    const lastName = [isNotNilOrEmpty(pu.last_name), "Last Name"] as const;
    const phone = [isNotNilOrEmpty(pu.phone), "Phone"] as const;
    const dob = [isNotNilOrEmpty(pu.dob), "Date of Birth"] as const;

    const userHasPersonalInformation =
      firstName[0] && lastName[0] && phone[0] && dob[0];

    // ------------------------------------------------------------

    // Address Info
    const address = [isNotNilOrEmpty(pu.address), "Address"] as const;
    const city = [isNotNilOrEmpty(pu.city), "City"] as const;
    const state = [isNotNilOrEmpty(pu.state), "State"] as const;
    const zipcode = [isNotNilOrEmpty(pu.zipcode), "Zipcode"] as const;

    const userHasUsaAddress =
      pu.country_code === "US" &&
      address[0] &&
      city[0] &&
      state[0] &&
      zipcode[0];

    const userHasCanadaAddress =
      pu.country_code === "CA" && address[0] && state[0];

    const userHasOtherAddress =
      pu.country_code !== "US" && pu.country_code !== "CA" && address[0];

    // ------------------------------------------------------------

    // Other Info
    const timezone = [
      isNotNilOrEmpty(pu.diagnostic_timezone),
      "Timezone",
    ] as const;

    const responsibleParty = [
      _.isNil(pu.protocol_type) ||
        pu.protocol_type === "Adult" ||
        (pu.protocol_type !== "Adult" &&
          isNotNilOrEmpty(pu.rp_name) &&
          isNotNilOrEmpty(pu.rp_email) &&
          isNotNilOrEmpty(pu.rp_email) &&
          isNotNilOrEmpty(pu.rp_relation)),
      "Responsible Party (minor)",
    ] as const;

    const parentLivingStatus = [
      pu.is_linked_account ? Boolean(pu.parents_living_status) : true,
      "Parent Living Status",
    ] as const;

    const userHasOtherInformation =
      timezone[0] && responsibleParty[0] && parentLivingStatus[0];

    // ------------------------------------------------------------

    const missingInfo = calculateMissingInfo(
      firstName,
      lastName,
      phone,
      dob,
      address,
      city,
      state,
      zipcode,
      timezone,
      responsibleParty,
      parentLivingStatus
    );

    return {
      isComplete:
        userHasPersonalInformation &&
        (userHasUsaAddress || userHasCanadaAddress || userHasOtherAddress) &&
        userHasOtherInformation,
      missingInfo,
    };
  }

  return {
    isComplete: false,
    missingInfo: { basicInfo: [], addressInfo: [], otherInfo: [] },
  };
};
