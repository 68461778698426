import { useSession } from "@core/hooks/useSession";
import {
  api,
  MemberActivationTargetsInterviewForm,
  transformAPIError,
} from "@core/services/nocd-api";
import { useQuery, UseQueryResult } from "react-query";

import getQueryKeys from "../query-keys";

export const useActivationTargetsInterviewForms = (
  userId: number
): UseQueryResult<MemberActivationTargetsInterviewForm[], Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).memberActivationTargetsInterviewForms(userId),
    (): Promise<MemberActivationTargetsInterviewForm[]> =>
      api
        .get<MemberActivationTargetsInterviewForm[]>(
          `/v2/clinician/members/${userId}/activation_targets_interview_form`,
          accessToken
            ? {
                headers: { Authorization: accessToken },
              }
            : undefined
        )
        .then(({ data }) => data)
        .catch(transformAPIError),
    { enabled: !!accessToken && userId != null }
  );
};
