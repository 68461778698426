import { useSession } from "@core/hooks/useSession";
import {
  api,
  getMemberQueryKey,
  transformAPIError,
} from "@core/services/nocd-api";
import {
  HLOCStatus,
  MemberHigherLevelOfCareNote,
} from "@core/services/nocd-api/members";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { useSWRConfig } from "swr";
import invariant from "tiny-invariant";

import getQueryKeys from "../../query-keys";

const createHLOCNote = (
  payload: MemberHigherLevelOfCareNote,
  accessToken: string | undefined
) => {
  invariant(payload.user_id != null, "user_id is required");
  return api
    .post<MemberHigherLevelOfCareNote[]>(
      `/v2/clinician/members/${encodeURIComponent(payload.user_id)}/hloc_notes`,
      {
        ...payload,
        note_date: payload.note_date
          ? new Date(payload.note_date).toISOString()
          : undefined,
        action: "ADD",
        is_referred_to_hloc_therapy:
          payload?.note_reason === HLOCStatus.REFERRED_TO_HLOC ? 1 : 0,
      },
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);
};

export const useCreateHLOCNote = (): UseMutationResult<
  MemberHigherLevelOfCareNote[],
  Error,
  MemberHigherLevelOfCareNote
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();
  const { mutate: globalMutate } = useSWRConfig();

  return useMutation((payload) => createHLOCNote(payload, accessToken), {
    onSuccess: (_data, payload) => {
      return Promise.all([
        queryClient.invalidateQueries(
          getQueryKeys(accessToken).memberHLOCNotes(payload.user_id)
        ),
        globalMutate(getMemberQueryKey(payload.user_id, accessToken)),
      ]);
    },
  });
};
