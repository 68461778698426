import { useSession } from "@core/hooks/useSession";
import { QueryKey, useQuery, UseQueryOptions } from "react-query";

import api from "../../api";
import { transformAPIError } from "../../utils";
import getQueryKeys from "../query-keys";
import { Trigger } from "../types";

const getMemberTriggers = (
  userId: number,
  accessToken: string | undefined
): Promise<Trigger[]> =>
  api
    .get<Trigger[]>(
      `/v1/therapy/members/${userId}/triggers`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);

export const useMemberTriggers = <T = Trigger[]>(
  userId: number,
  options?: UseQueryOptions<Trigger[], Error, T>
) => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).triggers(userId) as QueryKey,
    () => getMemberTriggers(userId, accessToken),
    {
      enabled: !!accessToken,
      ...(options ?? {}),
    }
  );
};
