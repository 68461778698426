import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { MemberReferralWithRelations } from "@core/services/nocd-api/members";
import { useQuery, UseQueryResult } from "react-query";

export const useMemberOutboundReferral = (
  outbound_referral_id: number | null
): UseQueryResult<MemberReferralWithRelations, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    ["outbound-referral", outbound_referral_id, accessToken],
    (): Promise<MemberReferralWithRelations> =>
      api
        .get<MemberReferralWithRelations>(
          `/v1/care_coordination/outbound_referrals/${outbound_referral_id}`,
          accessToken
            ? {
                headers: { Authorization: accessToken },
              }
            : undefined
        )
        .then(({ data }) => data)
        .catch(transformAPIError),
    { enabled: !!accessToken && outbound_referral_id != null }
  );
};
