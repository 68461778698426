import { useSession } from "@core/hooks/useSession";
import {
  api,
  MemberClinicalScratchpad,
  transformAPIError,
} from "@core/services/nocd-api";
import { useQuery, UseQueryResult } from "react-query";

import getQueryKeys from "../query-keys";

export const useMemberClinicalScratchpad = (
  userId: number
): UseQueryResult<MemberClinicalScratchpad, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).memberClinicalScratchpad(userId),
    (): Promise<MemberClinicalScratchpad> =>
      api
        .get<MemberClinicalScratchpad>(
          `/v2/clinician/members/${encodeURIComponent(
            userId
          )}/clinical_scratchpad`,
          accessToken
            ? {
                headers: { Authorization: accessToken },
              }
            : undefined
        )
        .then(({ data }) => data)
        .catch(transformAPIError),
    { enabled: !!accessToken && userId != null }
  );
};
