import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { MemberTreatmentMilestoneLetter } from "@core/services/nocd-api/members";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import invariant from "tiny-invariant";

import getQueryKeys from "../query-keys";

interface CreateMilestoneLetterFormPayload {
  id?: number;
  user_id: number;
  clinician_email: string;
  where_you_started: string;
  what_changed: string;
  impressed_by: string;
  what_we_learned: string;
  outcome_measures: {
    docs_percent_changed: number;
    dass_percent_changed_depression: number | null;
    dass_percent_changed_anxiety: number | null;
  };
  is_signed: boolean;
}

const createMilestoneLetterForm = (
  payload: CreateMilestoneLetterFormPayload,
  accessToken: string | undefined
) => {
  invariant(payload.user_id != null, "user_id is required");
  return api
    .post<MemberTreatmentMilestoneLetter>(
      `/v1/therapy/members/${encodeURIComponent(
        payload.user_id
      )}/milestone_letter`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => {
      return data;
    })
    .catch(transformAPIError);
};

export const useCreateMilestoneLetterForm = (): UseMutationResult<
  MemberTreatmentMilestoneLetter,
  Error,
  CreateMilestoneLetterFormPayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => createMilestoneLetterForm(payload, accessToken),
    {
      onSuccess: (_data, payload) =>
        queryClient.invalidateQueries(
          getQueryKeys(accessToken).memberTreatmentMilestoneLetter(
            payload.user_id
          )
        ),
    }
  );
};
