import { useSession } from "@core/hooks/useSession";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import api from "../../api";
import { transformAPIError } from "../../utils";
import getQueryKeys from "../query-keys";

interface ArchiveClinicalRecommendationsPayload {
  userId: number;
  id: number;
}

const archiveClinicalRecommendations = (
  values: ArchiveClinicalRecommendationsPayload,
  accessToken: string | undefined
) =>
  api
    .post<ArchiveClinicalRecommendationsPayload>(
      `/v2/clinician/members/${encodeURIComponent(
        values.userId
      )}/clinical_recommendations`,
      values,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);

export const useArchiveClinicalRecommendations = (): UseMutationResult<
  ArchiveClinicalRecommendationsPayload,
  Error,
  ArchiveClinicalRecommendationsPayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => archiveClinicalRecommendations(payload, accessToken),
    {
      onSuccess: (_response, { userId }) =>
        queryClient.invalidateQueries(
          getQueryKeys(accessToken).memberClinicalRecommendations(userId)
        ),
    }
  );
};
