import { useSession } from "@core/hooks/useSession";
import {
  api,
  MemberHealthcareProvider,
  transformAPIError,
} from "@core/services/nocd-api";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import getQueryKeys from "../../query-keys";

export interface CreateMemberHealthcareProviderPayload {
  id?: number;
  user_id: number;
  name: string | null;
  npi_number: string | null;
  npi_type: string | null;
  address_line_1: string | null;
  address_line_2: string | null;
  city: string | null;
  state: string | null;
  zipcode: string | null;
  phone: string | null;
  fax_number: string | null;
  specialty: string | null;
  title: string | null;
  source?: string;
  referred_member?: number | null;
  reason_working_with?: string | null;
}

const createMemberHealthcareProvider = (
  payload: CreateMemberHealthcareProviderPayload,
  accessToken: string | undefined
) => {
  return api
    .post<MemberHealthcareProvider[]>(
      `/v1/care_coordination/member_providers`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
            params: { user_id: payload.user_id },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);
};

export const useCreateMemberHealthcareProvider = (): UseMutationResult<
  MemberHealthcareProvider[],
  Error,
  CreateMemberHealthcareProviderPayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => createMemberHealthcareProvider(payload, accessToken),
    {
      onSuccess: (_data, payload) =>
        queryClient.invalidateQueries(
          getQueryKeys(accessToken).memberHealthcareProviders(payload.user_id)
        ),
    }
  );
};
