import { useSession } from "@core/hooks/useSession";
import {
  api,
  MemberPrimaryOutcomeForm,
  transformAPIError,
} from "@core/services/nocd-api";
import { useQuery, UseQueryResult } from "react-query";

import getQueryKeys from "../query-keys";

export const useMemberPrimaryOutcomeForm = (
  userId: number
): UseQueryResult<MemberPrimaryOutcomeForm, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).memberPrimaryOutcomeForm(userId),
    (): Promise<MemberPrimaryOutcomeForm> =>
      api
        .get<MemberPrimaryOutcomeForm>(
          `/v1/therapy/forms/${encodeURIComponent(
            userId
          )}/primary_outcomes_form`,
          accessToken
            ? {
                headers: { Authorization: accessToken },
              }
            : undefined
        )
        .then(({ data }) => data)
        .catch(transformAPIError),
    { enabled: !!accessToken && userId != null }
  );
};
