import { useSession } from "@core/hooks/useSession";
import {
  api,
  MemberActivationTargetsInterviewForm,
  transformAPIError,
} from "@core/services/nocd-api";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import invariant from "tiny-invariant";

import getQueryKeys from "../query-keys";

const createActivationTargetsInterviewForm = (
  payload: MemberActivationTargetsInterviewForm,
  accessToken: string | undefined
) => {
  invariant(payload.user_id != null, "user_id is required");
  return api
    .post<MemberActivationTargetsInterviewForm[]>(
      `/v2/clinician/members/${encodeURIComponent(
        payload.user_id
      )}/activation_targets_interview_form`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);
};

export const useCreateActivationTargetsInterviewForm = (): UseMutationResult<
  MemberActivationTargetsInterviewForm[],
  Error,
  MemberActivationTargetsInterviewForm
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => createActivationTargetsInterviewForm(payload, accessToken),
    {
      onSuccess: (_data, payload) =>
        queryClient.invalidateQueries(
          getQueryKeys(accessToken).memberActivationTargetsInterviewForms(
            payload.user_id
          )
        ),
    }
  );
};
