import { useSession } from "@core/hooks/useSession";
import {
  api,
  DefaultResponse,
  transformAPIError,
} from "@core/services/nocd-api";
import { useMutation, UseMutationResult } from "react-query";
import invariant from "tiny-invariant";

interface ResetMemberPasswordPayload {
  user_id: number;
}

const resetMemberPassword = (
  payload: ResetMemberPasswordPayload,
  accessToken: string | undefined
) => {
  invariant(payload.user_id != null, "user_id is required");
  return api
    .post<DefaultResponse>(
      `/v2/clinician/members/${encodeURIComponent(
        payload.user_id
      )}/password_reset`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);
};

export const useMemberResetPassword = (): UseMutationResult<
  unknown,
  Error,
  ResetMemberPasswordPayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useMutation((payload) => resetMemberPassword(payload, accessToken));
};
