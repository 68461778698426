import _ from "lodash";

export interface LabeledValue {
  label: string;
  value: string;
}

export interface LabeledNumberValue {
  label: string;
  value: number;
}

export const usaStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District Of Columbia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const canadaStates = [
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland and Labrador",
  "Northwest Territories",
  "Nova Scotia",
  "Nunavut",
  "Ontario",
  "Prince Edward Island",
  "Quebec",
  "Saskatchewan",
  "Yukon",
];

const countryLabels = [
  "United States of America",
  "Australia",
  "Canada",
  "United Kingdom",
  "Philippines",
  "Other",
];

const countryValues = ["US", "AU", "CA", "UK", "PH", "OT"];

export const countries: LabeledValue[] = _.zip(
  countryValues,
  countryLabels
).map(([value, label]) => ({ label, value }));

export const defaultCountryUsa = countries[0];

export const isNotNilOrEmpty = (arg: string) =>
  !_.isNil(arg) && !_.isEmpty(arg);
