import { useSession } from "@core/hooks/useSession";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import api from "../../api";
import { transformAPIError } from "../../utils";
import getQueryKeys from "../query-keys";

interface CreateMemberAdminNotePayload {
  text: string;
  user_id: number;
  needs_scheduling_team_assistance: boolean;
  department: string;
}

const createMemberAdminNote = (
  values: CreateMemberAdminNotePayload,
  accessToken: string | undefined
) =>
  api
    .post<unknown>(
      `/v1/therapy/members/${values.user_id}/admin_notes`,
      {
        text: values.needs_scheduling_team_assistance
          ? `Message to the scheduling team:\n${values.text}`
          : values.text,
        needs_scheduling_team_assistance:
          values.needs_scheduling_team_assistance,
        department: values.department,
      },
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);

export const useCreateMemberAdminNote = (): UseMutationResult<
  unknown,
  Error,
  CreateMemberAdminNotePayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation((payload) => createMemberAdminNote(payload, accessToken), {
    onSuccess: (_data, { user_id }) =>
      queryClient.invalidateQueries(
        getQueryKeys(accessToken).memberAdminNotes(user_id)
      ),
  });
};
