import { useSession } from "@core/hooks/useSession";
import {
  api,
  MemberHealthcareProvider,
  transformAPIError,
} from "@core/services/nocd-api";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import getQueryKeys from "../../query-keys";

interface DeleteMemberHealthcareProviderPayload {
  id: number;
  user_id: number;
}

const deleteHealthcareProvider = (
  payload: DeleteMemberHealthcareProviderPayload,
  accessToken: string | undefined
) => {
  return api
    .delete<MemberHealthcareProvider>(
      `/v1/care_coordination/member_providers/${payload.id}>`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);
};

export const useDeleteMemberHealthcareProvider = (): UseMutationResult<
  MemberHealthcareProvider,
  Error,
  DeleteMemberHealthcareProviderPayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => deleteHealthcareProvider(payload, accessToken),
    {
      onSuccess: (_data, payload) =>
        queryClient.invalidateQueries(
          getQueryKeys(accessToken).memberHealthcareProviders(payload.user_id)
        ),
    }
  );
};
