import { useCallback } from "react";

import { Trigger } from "../types";
import { useMemberTriggers } from "./useMemberTriggers";

export const useMemberTrigger = (triggerId: Trigger["id"], userId: number) =>
  useMemberTriggers(userId, {
    select: useCallback(
      (data: Trigger[]) => data.find((trigger) => trigger.id === triggerId),
      [triggerId]
    ),
  });
