import { useSession } from "@core/hooks/useSession";
import { getPremiumUserQueryKey } from "@features/intake/hooks/usePremiumUser";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import api from "../../api";
import getAppointmentQueryKeys from "../../appointments/query-keys";
import { transformAPIError } from "../../utils";
import getMemberQueryKeys from "../query-keys";

interface ChangeMemberEmailPayload {
  user_id: number;
  intake_call_id: number | string | null;
  current_email: string;
  new_email: string;
}

const changeMemberEmail = (
  payload: ChangeMemberEmailPayload,
  accessToken: string
) =>
  api
    .post(`/v1/retool/change_user_email`, payload, {
      headers: {
        Authorization: accessToken,
      },
    })
    .catch(transformAPIError);

export const useChangeMemberEmail = (): UseMutationResult<
  unknown,
  Error,
  ChangeMemberEmailPayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation((payload) => changeMemberEmail(payload, accessToken), {
    onSuccess: (_data, payload) =>
      Promise.all([
        queryClient.invalidateQueries(
          getAppointmentQueryKeys(accessToken).appointmentByMember(
            payload.user_id
          )
        ),
        queryClient.invalidateQueries(
          getMemberQueryKeys(accessToken).memberSchedulingOverview(
            payload.user_id
          )
        ),
        queryClient.invalidateQueries(["member-appointments"]),
        queryClient.invalidateQueries(["upcoming-first-session"]),
        queryClient.invalidateQueries(getPremiumUserQueryKey(payload.user_id)),
      ]),
  });
};
