import { useSession } from "@core/hooks/useSession";
import {
  api,
  MemberTraumaInterviewForm,
  transformAPIError,
} from "@core/services/nocd-api";
import { useQuery, UseQueryResult } from "react-query";

import getQueryKeys from "../query-keys";

export const useTraumaInterviewForms = (
  userId: number
): UseQueryResult<MemberTraumaInterviewForm[], Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).memberTraumaInterviewForms(userId),
    (): Promise<MemberTraumaInterviewForm[]> =>
      api
        .get<MemberTraumaInterviewForm[]>(
          `/v2/clinician/members/${userId}/trauma_interview_form`,
          accessToken
            ? {
                headers: { Authorization: accessToken },
              }
            : undefined
        )
        .then(({ data }) => data)
        .catch(transformAPIError),
    { enabled: !!accessToken && userId != null }
  );
};
