import { useSession } from "@core/hooks/useSession";
import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import api from "../../api";
import { transformAPIError } from "../../utils";
import getQueryKeys from "../query-keys";
import { MemberSchedulingOverview } from "../types";

const getMemberSchedulingOverview = (
  userId: number,
  accessToken: string | undefined
): Promise<MemberSchedulingOverview> =>
  api
    .get<MemberSchedulingOverview>(
      `/v2/therapy/members/${userId}/scheduling_overview`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);

export const useMemberSchedulingOverview = (
  userId: number,
  options?: UseQueryOptions<
    MemberSchedulingOverview,
    Error,
    MemberSchedulingOverview
  >
): UseQueryResult<MemberSchedulingOverview, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).memberSchedulingOverview(userId) as QueryKey,
    () => getMemberSchedulingOverview(userId, accessToken),
    {
      enabled: !!accessToken && userId != null,
      ...(options ?? {}),
    }
  );
};
