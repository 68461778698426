import { useSession } from "@core/hooks/useSession";
import {
  api,
  MemberHealthcareProvider,
  transformAPIError,
} from "@core/services/nocd-api";
import { useQuery, UseQueryOptions } from "react-query";

import getQueryKeys from "../query-keys";

export const useMemberHealthcareProviders = <T = MemberHealthcareProvider[]>(
  userId: number,
  options?: UseQueryOptions<MemberHealthcareProvider[], Error, T>
) => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).memberHealthcareProviders(userId),
    (): Promise<MemberHealthcareProvider[]> =>
      api
        .get<MemberHealthcareProvider[]>(
          `/v1/care_coordination/member_providers`,
          accessToken
            ? {
                headers: { Authorization: accessToken },
                params: { user_id: userId },
              }
            : undefined
        )
        .then(({ data }) => data)
        .catch(transformAPIError),
    { enabled: !!accessToken && userId != null, ...(options ?? {}) }
  );
};
