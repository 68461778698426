import { useSession } from "@core/hooks/useSession";
import { QueryKey, useQuery, UseQueryOptions } from "react-query";

import api from "../../api";
import { transformAPIError } from "../../utils";
import getQueryKeys from "../query-keys";
import { Obsession } from "../types";

const getMemberObsessions = (
  userId: number,
  accessToken: string | undefined
): Promise<Obsession[]> =>
  api
    .get<Obsession[]>(
      `/v1/therapy/members/${userId}/obsessions`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);

export const useMemberObsessions = <T = Obsession[]>(
  userId: number,
  options?: UseQueryOptions<Obsession[], Error, T>
) => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).obsessions(userId) as QueryKey,
    () => getMemberObsessions(userId, accessToken),
    {
      enabled: !!accessToken,
      ...(options ?? {}),
    }
  );
};
