import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { useQuery, UseQueryResult } from "react-query";

import getQueryKeys from "../query-keys";

export interface MemberAssessmentScoreDocs {
  id: number;
  user_id: number;
  completed_at: string;
  baseline_completed_at: string;
  score: number;
  baseline_score: number;
  score_diff: number;
  score_diff_perc: number;
  is_post_baseline: number;
  is_baseline: number;
  form_submissions_id: number;
  docs_contamination?: {
    score: number;
    score_diff_perc: number;
  };
  docs_responsibility?: {
    score: number;
    score_diff_perc: number;
  };
  docs_symmetry?: {
    score: number;
    score_diff_perc: number;
  };
  docs_thoughts?: {
    score: number;
    score_diff_perc: number;
  };
  subscore_name?: string;
}

export interface MemberAssessmentScore {
  id: number;
  user_id: number;
  completed_at: string;
  baseline_completed_at: string;
  score: number;
  baseline_score: number;
  score_diff: number;
  score_diff_perc: number;
  is_post_baseline: number;
  is_baseline: number;
  form_submissions_id: number;

  subscore_name?: string;
}
export interface MemberAssessmentResultsScores {
  dass_anxiety: MemberAssessmentScore[];
  dass_depression: MemberAssessmentScore[];
  dass_stress: MemberAssessmentScore[];
  docs: MemberAssessmentScoreDocs[];
  hrs: MemberAssessmentScore[];
  qles: MemberAssessmentScore[];
  rbfbs_nail: MemberAssessmentScore[];
  rbfbs_skin: MemberAssessmentScore[];
  rbfbs_hair: MemberAssessmentScore[];
  rbfbs_other: MemberAssessmentScore[];
  tic_motor: MemberAssessmentScore[];
  tic_vocal: MemberAssessmentScore[];
  tic_aggregate: MemberAssessmentScore[];
  whodas: MemberAssessmentScore[];
  pcl5: MemberAssessmentScore[];
  phq_9: MemberAssessmentScore[];
  mfq: MemberAssessmentScore[];
  lsas_total: MemberAssessmentScore[];
  lsas_fear: MemberAssessmentScore[];
  lsas_avoidance: MemberAssessmentScore[];
  lsas_ca_total: MemberAssessmentScore[];
  lsas_ca_fear: MemberAssessmentScore[];
  lsas_ca_avoidance: MemberAssessmentScore[];
  biq: MemberAssessmentScore[];
  gad_7: MemberAssessmentScore[];
  pswq_c: MemberAssessmentScore[];
  asi_3: MemberAssessmentScore[];
  smspa: MemberAssessmentScore[];
  smsada: MemberAssessmentScore[];
  shai: MemberAssessmentScore[];
  smaa: MemberAssessmentScore[];
  fas: MemberAssessmentScore[];
  p_qles_q: MemberAssessmentScore[];
  dass_y_stress: MemberAssessmentScore[];
  dass_y_anxiety: MemberAssessmentScore[];
  dass_y_depression: MemberAssessmentScore[];

  diamond_severity: {
    form_scores_data: {
      distress_score?: string;
      functional_score?: string;
    };
    id: string;
    completed_at?: string;
  }[];
}

export const useMemberAssessmentResultsScores = (
  userId: number
): UseQueryResult<MemberAssessmentResultsScores, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).memberAssessmentResultsScores(userId),
    (): Promise<MemberAssessmentResultsScores> =>
      api
        .get<MemberAssessmentResultsScores>(
          `/v2/clinician/members/${userId}/assessment_results_scores`,
          accessToken
            ? {
                headers: { Authorization: accessToken },
              }
            : undefined
        )
        .then(({ data }) => data)
        .catch(transformAPIError),
    { enabled: !!accessToken && userId != null }
  );
};
