import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { useQuery, UseQueryResult } from "react-query";

import { AWSFileContent } from "../../types";
import getQueryKeys from "../query-keys";

export const useMemberParentalAgreement = (
  userId: number,
  directory: string
): UseQueryResult<AWSFileContent[], Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).memberParentalAgreement(userId),
    (): Promise<AWSFileContent[]> =>
      api
        .get<AWSFileContent[]>(
          `v2/clinician/members/${userId}/files`,
          accessToken
            ? {
                headers: { Authorization: accessToken },
                params: {
                  directory,
                },
              }
            : undefined
        )
        .then(({ data }) => data)
        .catch(transformAPIError),
    { enabled: !!accessToken && userId != null }
  );
};
