import { useSession } from "@core/hooks/useSession";
import { api, MemberRelease, transformAPIError } from "@core/services/nocd-api";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import getQueryKeys from "../query-keys";

interface RevokeROIPayload {
  id: number;
  user_id: number;
  reason: string | null;
  author: string;
}

const revokeMemberRoi = (
  payload: RevokeROIPayload,
  accessToken: string | undefined
) => {
  return api
    .post<MemberRelease>(
      `/v1/therapy/roi_records/${payload.id}/revoke`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
            params: { user_id: payload.user_id },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);
};

export const useMemberRevokeRoi = (): UseMutationResult<
  MemberRelease,
  Error,
  RevokeROIPayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation((payload) => revokeMemberRoi(payload, accessToken), {
    onSuccess: (_data, payload) =>
      queryClient.invalidateQueries(
        getQueryKeys(accessToken).memberRoiRecords(payload.user_id)
      ),
  });
};
