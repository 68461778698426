import { useCallback } from "react";

import { Obsession } from "../types";
import { useMemberObsessions } from "./useMemberObsessions";

export const useMemberObsession = (
  obsessionId: Obsession["id"],
  userId: number
) =>
  useMemberObsessions(userId, {
    select: useCallback(
      (data: Obsession[]) =>
        data.find((obsession) => obsession.id === obsessionId),
      [obsessionId]
    ),
  });
