import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { MemberReferral } from "@core/services/nocd-api/members";
import { useQuery, UseQueryResult } from "react-query";

import getQueryKeys from "../query-keys";

export const useMemberOutboundReferrals = (
  userId: number
): UseQueryResult<MemberReferral[], Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).memberOutboundReferrals(userId),
    (): Promise<MemberReferral[]> =>
      api
        .get<MemberReferral[]>(
          "/v1/care_coordination/outbound_referrals",
          accessToken
            ? {
                headers: { Authorization: accessToken },
                params: { user_id: userId },
              }
            : undefined
        )
        .then(({ data }) => data)
        .catch(transformAPIError),
    { enabled: !!accessToken && userId != null }
  );
};
