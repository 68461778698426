import { useSession } from "@core/hooks/useSession";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import api from "../../api";
import { transformAPIError } from "../../utils";
import getQueryKeys from "../query-keys";

interface CreateMemberReminderPayload {
  title: string;
  member_user_id: number | null;
  remind_at: string | null;
}

const createMemberReminder = (
  values: CreateMemberReminderPayload,
  accessToken: string | undefined
) => {
  return api
    .post<unknown>(
      `/v1/ehr/notifications/member_reminders`,
      {
        title: values.title,
        user_id: values.member_user_id,
        remind_at: values.remind_at,
      },
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);
};
export const useCreateMemberReminder = (): UseMutationResult<
  unknown,
  Error,
  CreateMemberReminderPayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation((payload) => createMemberReminder(payload, accessToken), {
    onSuccess: (_data, { member_user_id }) =>
      queryClient.invalidateQueries(
        getQueryKeys(accessToken).memberReminders(member_user_id)
      ),
  });
};
