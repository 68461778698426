import { useSession } from "@core/hooks/useSession";
import {
  api,
  MemberPrimaryOutcomeForm,
  transformAPIError,
} from "@core/services/nocd-api";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import invariant from "tiny-invariant";

import getQueryKeys from "../query-keys";

interface SavePrimaryOutcomeFormPayload {
  userId: number;
  primaryFormName: string;
  primarySubscoreName: string;
}

const savePrimaryOutcomeForm = (
  payload: SavePrimaryOutcomeFormPayload,
  accessToken: string | undefined
) => {
  invariant(payload.userId != null, "user_id is required");
  return api
    .post<MemberPrimaryOutcomeForm>(
      `/v1/therapy/forms/${encodeURIComponent(
        payload.userId
      )}/primary_outcomes_form`,
      {
        primary_form_name: payload.primaryFormName,
        primary_form_subscore_name: payload.primarySubscoreName,
      },
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);
};

export const useSavePrimaryOutcomeForm = (): UseMutationResult<
  MemberPrimaryOutcomeForm,
  Error,
  SavePrimaryOutcomeFormPayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => savePrimaryOutcomeForm(payload, accessToken),
    {
      onSuccess: (_data, payload) =>
        queryClient.invalidateQueries(
          getQueryKeys(accessToken).memberPrimaryOutcomeForm(payload.userId)
        ),
    }
  );
};
