import { useSession } from "@core/hooks/useSession";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import api from "../../../api";
import { transformAPIError } from "../../../utils";
import getQueryKeys from "../../query-keys";
import { Trigger } from "../../types";

export type CreateTriggerPayload = Pick<
  Trigger,
  "trigger" | "severity" | "obsession_id" | "user_id"
>;

const createTrigger = (
  values: CreateTriggerPayload,
  accessToken: string | undefined
): Promise<Trigger> =>
  api
    .post<Trigger>(
      `/v1/therapy/obsessions/${values.obsession_id}/triggers`,
      {
        severity: values.severity,
        trigger: values.trigger,
        user_id: values.user_id,
      },
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);

export const useCreateTrigger = (): UseMutationResult<
  Trigger,
  Error,
  CreateTriggerPayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation((payload) => createTrigger(payload, accessToken), {
    onSuccess: async (updatedTrigger) => {
      const triggersQueryKey = getQueryKeys(accessToken).triggers(
        updatedTrigger.user_id
      );

      await queryClient.cancelQueries(triggersQueryKey);

      // See if there's any data in the query cache for triggers
      const previousTriggers =
        queryClient.getQueryData<Trigger[]>(triggersQueryKey);

      if (previousTriggers) {
        queryClient.setQueryData<Trigger[]>(triggersQueryKey, (triggers) =>
          triggers?.concat(updatedTrigger)
        );
      }
    },
  });
};
