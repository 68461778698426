import { useSession } from "@core/hooks/useSession";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import api from "../../../api";
import { transformAPIError } from "../../../utils";
import getQueryKeys from "../../query-keys";
import { Consequence } from "../../types";

export interface CreateConsequencePayload {
  consequence: Consequence["consequence"];
  obsession_id: Consequence["obsession_id"];
  user_id: number;
}

const createConsequence = (
  values: CreateConsequencePayload,
  accessToken: string | undefined
) =>
  api
    .post<Consequence>(
      `/v1/therapy/obsessions/${values.obsession_id}/consequences`,
      {
        consequence: values.consequence,
        user_id: values.user_id,
      },
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);

export const useCreateConsequence = (): UseMutationResult<
  Consequence,
  Error,
  CreateConsequencePayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation((payload) => createConsequence(payload, accessToken), {
    onSuccess: (_data, { user_id }) =>
      queryClient.invalidateQueries(
        getQueryKeys(accessToken).consequences(user_id)
      ),
  });
};
