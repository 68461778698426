import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { MemberFullResponseDocsOutcome } from "@core/services/nocd-api/members";
import { useQuery, UseQueryResult } from "react-query";

import getQueryKeys from "../query-keys";

export const useMemberFullResponseDocsOutcome = (
  userId: number
): UseQueryResult<MemberFullResponseDocsOutcome, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).memberFullResponseDocsOutcome(userId),
    (): Promise<MemberFullResponseDocsOutcome> =>
      api
        .get<MemberFullResponseDocsOutcome>(
          `/v1/therapy/members/${userId}/full_response_docs_outcome`,
          accessToken
            ? {
                headers: {
                  Authorization: accessToken,
                },
              }
            : undefined
        )
        .then(({ data }) => data)
        .catch(transformAPIError),
    { enabled: !!accessToken && userId != null }
  );
};
