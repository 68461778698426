import { useSession } from "@core/hooks/useSession";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import api from "../../api";
import { transformAPIError } from "../../utils";
import { MemberReminder } from "../queries/useMemberReminders";
import getQueryKeys from "../query-keys";

interface ResolveMemberReminderPayload {
  ehrNotificationId: number;
  memberUserId: number;
}

const resolveMemberReminder = (
  values: ResolveMemberReminderPayload,
  accessToken: string | undefined
) =>
  api
    .post<MemberReminder>(
      `/v1/ehr/notifications/${values.ehrNotificationId}/resolve`,
      {},
      {
        headers: {
          Authorization: accessToken,
        },
      }
    )
    .then(({ data }) => data)
    .catch(transformAPIError);

export const useResolveMemberReminder = (): UseMutationResult<
  MemberReminder,
  Error,
  ResolveMemberReminderPayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation((payload) => resolveMemberReminder(payload, accessToken), {
    onSuccess: (_response, { memberUserId }) =>
      queryClient.invalidateQueries(
        getQueryKeys(accessToken).memberReminders(memberUserId)
      ),
  });
};
