import { useSession } from "@core/hooks/useSession";
import { QueryKey, useQuery, UseQueryOptions } from "react-query";

import api from "../../api";
import { transformAPIError } from "../../utils";
import getQueryKeys from "../query-keys";
import { ExerciseSession } from "../types";

const getExerciseSessions = (
  userId: number,
  accessToken: string | undefined
): Promise<ExerciseSession[]> =>
  api
    .get<ExerciseSession[]>(
      `/v2/clinician/members/${userId}/exercise_sessions`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);

export const useExerciseSessions = <T = ExerciseSession[]>(
  userId: number,
  options?: UseQueryOptions<ExerciseSession[], Error, T>
) => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).exerciseSessions(userId) as QueryKey,
    () => getExerciseSessions(userId, accessToken),
    {
      enabled: !!accessToken && !!userId,
      ...(options ?? {}),
    }
  );
};
