import { useCallback } from "react";

import { Compulsion } from "../types";
import { useMemberCompulsions } from "./useMemberCompulsions";

export const useMemberCompulsion = (
  compulsionId: Compulsion["id"],
  userId: number
) =>
  useMemberCompulsions(userId, {
    select: useCallback(
      (data: Compulsion[]) =>
        data.find((compulsion) => compulsion.id === compulsionId),
      [compulsionId]
    ),
  });
