import { useSession } from "@core/hooks/useSession";
import {
  api,
  MemberClinicalScratchpad,
  transformAPIError,
} from "@core/services/nocd-api";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import getQueryKeys from "../query-keys";

const saveClinicalScratchpad = (
  payload: MemberClinicalScratchpad,
  accessToken: string | undefined
) =>
  api
    .post<MemberClinicalScratchpad>(
      `/v2/clinician/members/${encodeURIComponent(
        payload.user_id
      )}/clinical_scratchpad`,
      {
        clinical_scratchpad: payload.clinical_scratchpad,
      },
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);

export const useSaveClinicalScratchpad = (): UseMutationResult<
  MemberClinicalScratchpad,
  Error,
  MemberClinicalScratchpad
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => saveClinicalScratchpad(payload, accessToken),
    {
      onSuccess: (_data, { user_id }) =>
        queryClient.invalidateQueries(
          getQueryKeys(accessToken).memberClinicalScratchpad(user_id)
        ),
    }
  );
};
