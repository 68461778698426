import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { MemberReferral } from "@core/services/nocd-api/members";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import getQueryKeys from "../../query-keys";

interface UpdateOutboundReferralContactStatusPayload {
  id: number;
  user_id: number;
  connected_with_external_provider: string;
  still_want_to_be_referred: string;
  new_referral_needed: string;
  still_want_to_be_referred_note: string;
  new_referral_needed_note: string;
  status: string;

  problem_area: string;
  type_of_treatment: string;
  request_notes: string;
  setting_preference: string;
  needs_treatment_for_psychotic_disorder: number | null;
  is_actively_suicidal: number | null;
  admitted_to_hospital_for_psychiatric_reason: number | null;
  has_medicaid: number | null;
  isVerified: boolean;
  preferred_times: {
    id: string;
    day: string;
    timeOfDay: string;
  }[];
  is_national_provider_referral: boolean;
}

// Update referral contact status with external provider
const updateOutboundReferralContactStatus = (
  accessToken: string,
  payload: UpdateOutboundReferralContactStatusPayload
) => {
  return api
    .patch<MemberReferral>(
      `/v1/care_coordination/outbound_referrals/${payload.id}/update_contact_status`,
      payload,
      {
        headers: {
          Authorization: accessToken,
        },
      }
    )
    .then(({ data }) => data)
    .catch(transformAPIError);
};

export const useUpdateOutboundReferralContactStatus = (): UseMutationResult<
  MemberReferral,
  Error,
  UpdateOutboundReferralContactStatusPayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => updateOutboundReferralContactStatus(accessToken, payload),
    {
      onSuccess: (_, payload) =>
        queryClient.invalidateQueries(
          getQueryKeys(accessToken).memberOutboundReferrals(payload.user_id)
        ),
    }
  );
};
