import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { useQuery, UseQueryResult } from "react-query";

import getQueryKeys from "../query-keys";
import {
  MemberAssessmentScore,
  MemberAssessmentScoreDocs,
} from "./useMemberAssessmentResultScores";

export interface MemberAssessmentResultsScoresV2 {
  dass: {
    dass_anxiety: MemberAssessmentScore;
    dass_depression: MemberAssessmentScore;
    dass_stress: MemberAssessmentScore;
  }[];
  dass_y: {
    dass_y_anxiety: MemberAssessmentScore;
    dass_y_depression: MemberAssessmentScore;
    dass_y_stress: MemberAssessmentScore;
  }[];
  rbfbs: {
    bfrb_bite_nails: MemberAssessmentScore;
    bfrb_pick_skin: MemberAssessmentScore;
    bfrb_pull_hair: MemberAssessmentScore;
    bfrb_other: MemberAssessmentScore;
  }[];
  tic: {
    tic_motor: MemberAssessmentScore;
    tic_vocal: MemberAssessmentScore;
    tic_aggregate: MemberAssessmentScore;
  }[];
  lsas: {
    lsas_total: MemberAssessmentScore;
    lsas_fear: MemberAssessmentScore;
    lsas_avoidance: MemberAssessmentScore;
  }[];
  lsas_ca: {
    lsas_ca_total: MemberAssessmentScore;
    lsas_ca_fear: MemberAssessmentScore;
    lsas_ca_avoidance: MemberAssessmentScore;
  }[];
  docs: MemberAssessmentScoreDocs[];
  hrs: MemberAssessmentScore[];
  qles: MemberAssessmentScore[];
  whodas: MemberAssessmentScore[];
  pcl5: MemberAssessmentScore[];
  phq_9: MemberAssessmentScore[];
  mfq: MemberAssessmentScore[];
  biq: MemberAssessmentScore[];
  gad_7: MemberAssessmentScore[];
  pswq_c: MemberAssessmentScore[];
  asi_3: MemberAssessmentScore[];
  smspa: MemberAssessmentScore[];
  smsada: MemberAssessmentScore[];
  shai: MemberAssessmentScore[];
  smaa: MemberAssessmentScore[];
  fas: MemberAssessmentScore[];
  p_qles_q: MemberAssessmentScore[];
  diamond_severity: {
    form_scores_data: {
      distress_score?: string;
      functional_score?: string;
    };
    id: string;
    completed_at?: string;
  }[];
}

export const useMemberAssessmentresultScoresV2 = (
  userId: number
): UseQueryResult<MemberAssessmentResultsScoresV2, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).memberAssessmentResultsScores(userId),
    (): Promise<MemberAssessmentResultsScoresV2> =>
      api
        .get<MemberAssessmentResultsScoresV2>(
          `/v2/clinician/members/${userId}/v2/assessment_results_scores`,
          accessToken
            ? {
                headers: { Authorization: accessToken },
              }
            : undefined
        )
        .then(({ data }) => data)
        .catch(transformAPIError),
    { enabled: !!accessToken && userId != null }
  );
};
