import { useSession } from "@core/hooks/useSession";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import api from "../../../api";
import { transformAPIError } from "../../../utils";
import getQueryKeys from "../../query-keys";
import { Trigger } from "../../types";

export type EditTriggerPayload = Pick<
  Trigger,
  "id" | "trigger" | "severity" | "user_id"
>;

const editTrigger = (
  values: EditTriggerPayload,
  accessToken: string | undefined
): Promise<Trigger> =>
  api
    .patch<Trigger>(
      `/v1/therapy/triggers/${values.id}`,
      {
        severity: values.severity,
        trigger: values.trigger,
      },
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);

export const useEditTrigger = (): UseMutationResult<
  Trigger,
  Error,
  EditTriggerPayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation((payload) => editTrigger(payload, accessToken), {
    onSuccess: (updatedTrigger) =>
      queryClient.invalidateQueries(
        getQueryKeys(accessToken).triggers(updatedTrigger.user_id)
      ),
  });
};
